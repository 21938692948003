import { TableBody, TableBodyProps } from '@mui/material';
import { flexRender, Row, RowModel, Table } from '@tanstack/react-table';
import { ESTableBodyCell } from './ESTableBodyCell';
import { DEFAULT_ROW_HEIGHT, ESTableBodyRow } from './ESTableBodyRow';
import { useTableIntersectionObserver } from './useTableIntersectionObsever';
import { memo } from 'react';
import { generateTableCellStyles } from './generateTableCellStyles';

interface ESTableBodyProps<T> extends TableBodyProps {
  rows: Row<T>[];
  onRowClick?: (row: Row<T>) => void;
  rowHeight?: number;
  observeRowsEnabled?: boolean;
  observeRowsSelectId?: (row: Row<T>) => string;
  observeRowsOnViewportEnter?: (rowIds: string[]) => void;
  observeRowsOnViewportExit?: (rowIds: string[]) => void;
  testId?: string;
  // NOTE: rowSelection needs to be passed as a separate prop
  // in order ro re-render the table when selection is changed.
  // Since table instance doesn't change reference on re-render we cannot check for instance.getSelectedRowModel()
  // as it will be the same in either previousProps and nextProps resulting in change in selection not being reflected in the UI.
  rowSelection?: RowModel<T>;
  table?: Table<T>;
}

const ESTableBodyInner = <T,>({
  rows,
  onRowClick,
  rowHeight = DEFAULT_ROW_HEIGHT,
  observeRowsEnabled = false,
  observeRowsSelectId,
  observeRowsOnViewportEnter,
  observeRowsOnViewportExit,
  testId,
  rowSelection,
  ...other
}: ESTableBodyProps<T>) => {
  useTableIntersectionObserver(
    '#tableContainerRef',
    '[data-row-id]',
    (rows) => {
      observeRowsOnViewportEnter?.(
        rows.map((row) => row.getAttribute('data-row-id') ?? '')
      );
    },
    (rows) => {
      observeRowsOnViewportExit?.(
        rows.map((row) => row.getAttribute('data-row-id') ?? '')
      );
    },
    rows,
    observeRowsEnabled
  );

  return (
    <TableBody id="tableBodyRef" data-testid={`${testId}TableBody`} {...other}>
      {rows.map((row, index) => {
        return (
          <ESTableBodyRow
            data-testid={`${testId}TableRow${index}`}
            rowHeight={rowHeight}
            key={row.id}
            data-row-id={observeRowsSelectId?.(row)}
            onRowClick={() => onRowClick?.(row)}
          >
            {row.getVisibleCells().map((cell, i, cells) => {
              const isActionsColumn =
                cells.length - 1 === i && cell.id.includes('actions');
              const cellStyles = generateTableCellStyles(cell, isActionsColumn);

              return (
                <ESTableBodyCell
                  key={cell.id}
                  align={isActionsColumn ? 'right' : 'left'}
                  isSticky={isActionsColumn}
                  onClick={(e) => {
                    if (isActionsColumn) {
                      e.stopPropagation();
                    }
                  }}
                  sx={cellStyles}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </ESTableBodyCell>
              );
            })}
          </ESTableBodyRow>
        );
      })}
    </TableBody>
  );
};
const MemoizedTableBody = memo(ESTableBodyInner) as typeof ESTableBodyInner;

export const ESTableBody = <T,>({ table, ...rest }: ESTableBodyProps<T>) => {
  if (table && table.getState().columnSizingInfo.isResizingColumn) {
    return <MemoizedTableBody {...rest} />;
  }

  return <ESTableBodyInner {...rest} />;
};
