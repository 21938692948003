import { ChargingHubModel } from '@energy-stacks/obelis/feature-charging-hubs-data';
import { Fragment, useRef, useState } from 'react';
import { ESMap, useCustomZoom } from '@energy-stacks/core/ui';
import { coordsToNumbers } from '@energy-stacks/shared';
import { MarkerF } from '@react-google-maps/api';
import { obelisPOIMarker } from '@energy-stacks/obelis/shared';
import { ChargingHubMapPopup } from './ChargingHubMapPopup';

interface ChargingHubsMapProps {
  chargingHubs: ChargingHubModel[] | undefined;
}

export const ChargingHubsMap: React.FC<ChargingHubsMapProps> = ({
  chargingHubs,
}) => {
  const mapRef = useRef<google.maps.Map | undefined>();
  const [focusedChargingHubId, setFocusedChargingHubId] = useState<
    ChargingHubModel['id'] | null
  >(null);
  const { handleZoomChange } = useCustomZoom(mapRef);

  return (
    <ESMap
      onLoad={(map) => {
        mapRef.current = map;
        if (!chargingHubs?.length) {
          map.setCenter({
            lat: 0,
            lng: 0,
          });
          return;
        }
        // Setting Markers into the bounds
        const bounds = new window.google.maps.LatLngBounds();

        chargingHubs.map((chargingHub) => {
          const convertedToLatLng: google.maps.LatLng = coordsToNumbers(
            chargingHub.coordinates
          );

          return bounds.extend(convertedToLatLng);
        });

        map.fitBounds(bounds);
      }}
      zoom={8}
      onZoomChanged={handleZoomChange}
      options={{
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.SATELLITE,
          ],
        },
      }}
    >
      {chargingHubs?.map((chargingHub) => {
        const markerCenter = coordsToNumbers(chargingHub.coordinates);
        return (
          <Fragment key={chargingHub.id}>
            <MarkerF
              key={
                focusedChargingHubId === chargingHub.id
                  ? 'focused'
                  : chargingHub.id
              }
              draggable={false}
              position={markerCenter}
              icon={{
                url: obelisPOIMarker,
                scaledSize:
                  chargingHub.id === focusedChargingHubId
                    ? new google.maps.Size(60, 60)
                    : new google.maps.Size(50, 50),
              }}
              onClick={() => {
                setFocusedChargingHubId(chargingHub.id);
              }}
            />
            {focusedChargingHubId === chargingHub.id && (
              <ChargingHubMapPopup
                chargingHub={chargingHub}
                onClose={() => setFocusedChargingHubId(null)}
              />
            )}
          </Fragment>
        );
      })}
    </ESMap>
  );
};
