import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type ProductTypeChipProps = {
  productName: string;
  isSequentialRaw?: boolean;
};

type ProductTypeColorsMap = Record<
  string,
  {
    backgroundColor: string;
    color: string;
  }
>;

const productTypeColorsMap: ProductTypeColorsMap = {
  raw: {
    backgroundColor: 'cyan.50',
    color: 'cyan.600',
  },
  degassed: {
    backgroundColor: 'warning.light',
    color: 'warning.800',
  },
  sequentialRaw: {
    backgroundColor: 'pink.50',
    color: 'error.dark',
  },
  default: {
    backgroundColor: 'grey.200',
    color: 'grey.600',
  },
};

export const ProductTypeChip: FC<ProductTypeChipProps> = ({
  productName,
  isSequentialRaw,
}) => {
  const [t] = useTranslation('jobs');

  const { backgroundColor, color } = isSequentialRaw
    ? productTypeColorsMap['sequentialRaw']
    : productTypeColorsMap[productName] ?? productTypeColorsMap['default'];

  return (
    <Chip
      size="medium"
      label={t(`productTypes.${productName}`, productName)}
      sx={{
        backgroundColor,
        color,
        '&.MuiChip-root': {
          cursor: 'inherit',
          textTransform: 'capitalize',
        },
      }}
    />
  );
};
