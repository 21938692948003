import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Geocode from 'react-geocode';
import { MarkerF } from '@react-google-maps/api';
import { IconPencil } from '@tabler/icons-react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { ESMap } from '@energy-stacks/core/ui';
import { coordsToNumbers } from '@energy-stacks/shared';
import { obelisPOIMarker, ObelisPOIMarker } from '@energy-stacks/obelis/shared';

export type Coordinates = {
  latitude: string;
  longitude: string;
};

type ChargingHubLocationMapViewProps = {
  coords: Coordinates | null;
  editRoute: string;
};

export const ChargingHubDetailsLocationMapView: FC<
  ChargingHubLocationMapViewProps
> = ({ coords, editRoute }) => {
  const [t] = useTranslation('chargingHubs');
  const { palette, spacing, shadows } = useTheme();
  const [address, setAddress] = useState('');
  const navigate = useNavigate();

  const mapCenter = coordsToNumbers(coords);

  const getAddressFromCoords = useCallback(async () => {
    try {
      const latitudeStr = String(coords?.latitude || 0);
      const longitudeStr = String(coords?.longitude || 0);
      const addressResult = await Geocode.fromLatLng(latitudeStr, longitudeStr);
      return addressResult.results[0].formatted_address;
    } catch (error) {
      return t('locationDialogSearchAddressError');
    }
  }, [coords, t]);

  useEffect(() => {
    let isMounted = true;
    getAddressFromCoords()
      .then((res) => {
        if (isMounted) {
          setAddress(res);
        }
      })
      .catch(() => setAddress(t('locationDialogSearchAddressError')));

    return () => {
      isMounted = false;
    };
  }, [coords, getAddressFromCoords, t]);

  return (
    <ESMap
      center={mapCenter}
      zoom={coords ? 16 : 2}
      options={{
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.SATELLITE,
          ],
        },
      }}
    >
      <MarkerF
        position={mapCenter}
        draggable={false}
        clickable={false}
        icon={{
          url: obelisPOIMarker,
          scaledSize: new google.maps.Size(48, 48),
        }}
      />
      <Box
        // absolute positioning - fixed values
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper',
          boxShadow: shadows[1],
          p: 3,
          position: 'absolute',
          left: spacing(4),
          right: spacing(4),
          bottom: spacing(7),
          maxWidth: '515px',
          minWidth: 'fit-content',
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            mb: 3.5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <ObelisPOIMarker />
            <Typography
              sx={{
                color: palette.grey[900],
                flexGrow: 1,
                ml: 2,
              }}
            >
              {t('locationDialogSearchAddressLabel')}
            </Typography>
          </Box>
          <IconButton
            onClick={() => navigate(editRoute)}
            sx={{
              // requested is 28px, but size property small is by default 34px
              height: spacing(7),
              width: spacing(7),
              p: 0,
            }}
          >
            <IconPencil
              style={{ cursor: 'pointer', color: palette.grey[500] }}
            />
          </IconButton>
        </Box>
        <Typography
          sx={{
            color: palette.grey[900],
            fontWeight: '500',
          }}
        >
          {address}
        </Typography>
      </Box>
    </ESMap>
  );
};
