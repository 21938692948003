import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { ESTextButton } from '@energy-stacks/core/ui';

interface ClearFiltersButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableInstance: Table<any>;
  // in some cases filter always needs to be set so no need to clear it
  ignoreColumnIds?: string[];
  clearFilters?: () => void;
  onFiltersCleared?: () => void;
}

const areAllFiltersEmpty = (filters: ColumnFiltersState) => {
  if (!filters.length) {
    return true;
  }

  return !filters.find((filter) => {
    if (Array.isArray(filter.value)) {
      return !!filter.value.length;
    }

    return !!filter.value;
  });
};

export const ClearFiltersButton: React.FC<ClearFiltersButtonProps> = ({
  tableInstance,
  ignoreColumnIds,
  clearFilters,
  onFiltersCleared,
}) => {
  const [t] = useTranslation('shared');
  const { columnFilters } = tableInstance.getState();

  const clear = useCallback(() => {
    onFiltersCleared?.();
    tableInstance.setColumnFilters([]);
  }, [onFiltersCleared, tableInstance]);

  const showClear = useMemo(() => {
    // There might be the case when filter value is empty array (e.g. [{id: "filterId", value: []}])
    // In that case filter should not be taken into account
    const filtersEmpty = areAllFiltersEmpty(columnFilters);

    return !ignoreColumnIds
      ? columnFilters.length && !filtersEmpty
      : columnFilters.filter(({ id }) => !ignoreColumnIds.includes(id))
          .length && !filtersEmpty;
  }, [columnFilters, ignoreColumnIds]);

  return showClear ? (
    <ESTextButton onClick={clearFilters || clear}>{t('clearAll')}</ESTextButton>
  ) : null;
};
