import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import {
  BaseQueryApi,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

export const extractPlantIdFromBaseQueryApi = (api: BaseQueryApi): string => {
  const state = api.getState() as object;
  let plantId = '';
  if ('plant' in state) {
    const plant = state.plant as object;
    if ('selectedPlantId' in plant) {
      plantId = plant.selectedPlantId as string;
    }
  }

  return plantId;
};

type Args<QueryArg> = {
  api: BaseQueryApi;
  baseQuery: (
    arg: string | FetchArgs
  ) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, object>>;
  url: string;
  transformResponse: (data: any) => any;
  params?: QueryArg;
};

export const getDataByPlantId = async <
  QueryArg,
  TDataDto extends { depotId: string }
>(
  args: Args<QueryArg>
) => {
  const { api, baseQuery, transformResponse, url, params } = args;
  const selectedPlantId = extractPlantIdFromBaseQueryApi(api);
  const response = await baseQuery({
    url: url,
    method: 'GET',
    params: {
      depotId: selectedPlantId,
      ...(params ?? {}),
    },
  });

  const { data, error } = response;

  if (error) {
    return { error };
  }

  return {
    data: transformResponse((data ?? []) as TDataDto),
  };
};
