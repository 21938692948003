import {
  ESButton,
  ESDialogActions,
  ESTextField,
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { EditableMap } from '@energy-stacks/shared';
import { Box, Grid } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { editChargingStationValidationScheme } from './editChargingStationValidationScheme';
import {
  ChargingStationDetails,
  chargingStationsApiErrors,
  useEditChargingStationMutation,
} from '@energy-stacks/obelis/feature-charging-stations-data';
import {
  OBELIS_DEFAULT_COORDINATES,
  obelisPOIMarker,
} from '@energy-stacks/obelis/shared';

type EditChargingStationFormData = {
  id: string;
  name: string;
  chargingHub: {
    value: string;
    label: string;
  };
  vendor?: string;
  model?: string;
  coordinates: {
    latitude: string;
    longitude: string;
  };
};

type EditChargingStationFormProps = {
  chargingStationDetails: ChargingStationDetails;
  onFormDirty: (isDirty: boolean) => void;
  closeDialog: () => void;
};

export const EditChargingStationForm: FC<EditChargingStationFormProps> = ({
  onFormDirty,
  closeDialog,
  chargingStationDetails,
}) => {
  const [t] = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const [isMapReady, setIsMapReady] = useState(false);
  const [editChargingStation, { isLoading }] = useEditChargingStationMutation();

  const editChargingStationDefaultValues = useMemo(
    () => ({
      id: chargingStationDetails.id,
      name: chargingStationDetails.name,
      chargingHub: {
        label: chargingStationDetails.chargingHub.name,
        value: chargingStationDetails.chargingHub.id,
      },
      vendor: chargingStationDetails.vendor,
      model: chargingStationDetails.vendorModel,
      coordinates: {
        latitude: chargingStationDetails.coordinates.latitude,
        longitude: chargingStationDetails.coordinates.longitude,
      },
    }),
    [chargingStationDetails]
  );

  const methods = useForm({
    defaultValues: editChargingStationDefaultValues,
    mode: 'onTouched',
    resolver: yupResolver(editChargingStationValidationScheme),
  });
  const {
    formState: { isDirty, isValid, errors },
    control,
    setValue,
    trigger,
    handleSubmit,
  } = methods;

  const watchLongitude = useWatch({
    name: 'coordinates.longitude',
    control,
  });
  const watchLatitude = useWatch({
    name: 'coordinates.latitude',
    control,
  });

  const handleCoordsChange = (
    newCoords: EditChargingStationFormData['coordinates']
  ) => {
    setValue('coordinates.latitude', newCoords.latitude.slice(0, 10), {
      shouldValidate: true,
    });
    setValue('coordinates.longitude', newCoords.longitude.slice(0, 11), {
      shouldValidate: true,
    });
  };

  const onSubmit: SubmitHandler<EditChargingStationFormData> = useCallback(
    (data) => {
      editChargingStation({
        id: chargingStationDetails.id,
        body: {
          name: data.name,
          chargingHubUid: chargingStationDetails.chargingHub.id,
          coordinates: {
            latitude: data.coordinates.latitude,
            longitude: data.coordinates.longitude,
          },
          chargingStationVendor: data.vendor,
          chargingStationVendorModel: data.model,
        },
      })
        .unwrap()
        .then(() => {
          showSnackbar(
            'success',
            'editChargingStationSuccess',
            'chargingStations'
          );
          closeDialog();
        })
        .catch((error) => {
          const errorCode = chargingStationsApiErrors[error.data?.errorCode];
          showSnackbar(
            'error',
            errorCode ? `chargingStationsApiErrors.${errorCode}` : undefined,
            'chargingStations'
          );
        });
    },
    [editChargingStation, chargingStationDetails, showSnackbar, closeDialog]
  );

  useEffect(() => {
    onFormDirty(isDirty);
  }, [onFormDirty, isDirty]);

  return (
    <FormProvider {...methods}>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value } }) => {
                    return (
                      <ESTextField
                        label={t('chargingStationGeneralDetailsLabels.id')}
                        type="text"
                        required
                        disabled
                        value={value}
                        inputProps={{
                          'data-testid': 'chargingStationIdTextField',
                        }}
                      />
                    );
                  }}
                  name="id"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESTextField
                        label={t('chargingStationGeneralDetailsLabels.name')}
                        required
                        type="text"
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors['name'])}
                        helperText={
                          Boolean(errors['name']) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['name']?.message}`
                          )
                        }
                        value={value}
                        inputProps={{
                          'data-testid': 'chargingStationNameTextField',
                        }}
                      />
                    );
                  }}
                  name="name"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value } }) => (
                    <ESVirtualizedAutocomplete
                      value={value}
                      options={[]}
                      label={t(
                        'chargingStationGeneralDetailsLabels.chargingHub'
                      )}
                      required
                      disabled
                    />
                  )}
                  name="chargingHub"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESTextField
                        label={t('chargingStationGeneralDetailsLabels.vendor')}
                        type="text"
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors['vendor'])}
                        helperText={
                          Boolean(errors['vendor']) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['vendor']?.message}`
                          )
                        }
                        value={value}
                        inputProps={{
                          'data-testid': 'chargingStationVendorTextField',
                        }}
                      />
                    );
                  }}
                  name="vendor"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <ESTextField
                        label={t('chargingStationGeneralDetailsLabels.model')}
                        type="text"
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors['model'])}
                        helperText={
                          Boolean(errors['model']) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['model']?.message}`
                          )
                        }
                        value={value}
                        inputProps={{
                          'data-testid': 'chargingStationVendorModelTextField',
                        }}
                      />
                    );
                  }}
                  name="model"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Box height={370}>
                  <EditableMap
                    icon={obelisPOIMarker}
                    center={{
                      latitude:
                        watchLatitude || OBELIS_DEFAULT_COORDINATES.latitude,
                      longitude:
                        watchLongitude || OBELIS_DEFAULT_COORDINATES.longitude,
                    }}
                    zoom={
                      watchLatitude !==
                        editChargingStationDefaultValues.coordinates.latitude ||
                      watchLongitude !==
                        editChargingStationDefaultValues.coordinates.longitude
                        ? 13
                        : 3
                    }
                    onMapReady={() => setIsMapReady(true)}
                    onCoordsChange={handleCoordsChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <ESTextField
                        value={value}
                        label={t(
                          'chargingStationGeneralDetailsLabels.latitude'
                        )}
                        type="text"
                        required
                        onChange={(event) => {
                          const isValid =
                            !isNaN(Number(event?.target.value)) ||
                            event?.target.value === '-';
                          if (event === undefined || isValid) {
                            trigger('coordinates.longitude');
                            onChange(event);
                          }
                        }}
                        onBlur={onBlur}
                        error={Boolean(errors['coordinates']?.latitude)}
                        helperText={
                          Boolean(errors['coordinates']?.latitude) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['coordinates']?.latitude?.message}`
                          )
                        }
                        inputProps={{
                          'data-testid': 'addStationLatitudeTextField',
                        }}
                      />
                    );
                  }}
                  name="coordinates.latitude"
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <ESTextField
                        value={value}
                        label={t(
                          'chargingStationGeneralDetailsLabels.longitude'
                        )}
                        type="text"
                        required
                        onChange={(event) => {
                          const isValid =
                            !isNaN(Number(event?.target.value)) ||
                            event?.target.value === '-';
                          if (event === undefined || isValid) {
                            trigger('coordinates.latitude');
                            onChange(event);
                          }
                        }}
                        onBlur={onBlur}
                        error={Boolean(errors['coordinates']?.longitude)}
                        helperText={
                          Boolean(errors['coordinates']?.longitude) &&
                          t(
                            `chargingStationGeneralDetailsErrors.${errors['coordinates']?.longitude?.message}`
                          )
                        }
                        inputProps={{
                          'data-testid': 'addStationLongitudeTextField',
                        }}
                      />
                    );
                  }}
                  name="coordinates.longitude"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ESDialogActions sx={{ pt: 10, px: 0, width: '100%' }}>
          <ESButton
            disabled={!isValid || !isMapReady || !isDirty}
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t('editChargingStationDialogButton')}
          </ESButton>
        </ESDialogActions>
      </Box>
    </FormProvider>
  );
};
