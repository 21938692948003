import { TableHead, TableRow } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { ESTableHeaderCell } from './ESTableHeaderCell';
import { TableDataFetchingIndicator } from './TableDataFetchingIndicator';

export const TABLE_HEADER_HEIGHT = 70.59;

export const ESTableHead = <T,>({
  instance,
  testId,
  fontWeight = 700,
  height = TABLE_HEADER_HEIGHT,
  showProgress = false,
}: {
  instance: Table<T>;
  testId?: string;
  fontWeight?: number;
  height?: number;
  showProgress?: boolean;
}) => {
  return (
    <TableHead
      data-testid={`${testId}TableHead`}
      sx={{
        height,
        overflowAnchor: 'none',
        position: 'sticky',
        top: 0,
        zIndex: 2,
      }}
    >
      {ESTableHeadContentRenderer(instance, testId, height, fontWeight)}
      {showProgress ? <TableDataFetchingIndicator /> : null}
    </TableHead>
  );
};

export const ESTableHeadContentRenderer = <T,>(
  instance: Table<T>,
  testId?: string,
  height?: number,
  fontWeight?: number
) => {
  return instance.getHeaderGroups().map((headerGroup, index) => {
    return (
      <TableRow
        data-testid={`${testId}TableHeadRow${index}`}
        key={headerGroup.id}
        sx={{ height }}
      >
        {headerGroup.headers.map((header, i, headers) => {
          const isActionsColumn =
            headers.length - 1 === i && header.id.includes('actions');
          const isExpandColumn = 0 === i && header.id.includes('expand');

          // CSS vars are defined in ESTable component
          const headerSize = `calc(var(--header-${header?.id}-size) * 1px)`;

          const headerCellWidthStyles = header.column.getCanResize()
            ? {
                width:
                  !isActionsColumn && !isExpandColumn ? headerSize : undefined,
                minWidth:
                  !isActionsColumn && !isExpandColumn ? headerSize : undefined,
                maxWidth:
                  !isActionsColumn && !isExpandColumn ? headerSize : undefined,
              }
            : {
                width: !isActionsColumn && !isExpandColumn ? headerSize : '0px',
              };

          return (
            <ESTableHeaderCell
              testId={`${testId}${header.id}`}
              header={header}
              key={header.id}
              sx={{
                '&': {
                  ...headerCellWidthStyles,
                  height,
                },
                whiteSpace: 'nowrap',
                '&.MuiTableCell-head': {
                  backgroundColor:
                    header.column.columnDef.meta?.backgroundColor,
                  position: header.column.getCanResize()
                    ? 'relative'
                    : undefined,
                  fontWeight,
                  padding: header.column.columnDef.meta?.cellPadding,
                },
              }}
              align={isActionsColumn ? 'right' : 'left'}
              isSticky={isActionsColumn}
            />
          );
        })}
      </TableRow>
    );
  });
};
