import { TableContainer, Table, Paper, SxProps, Theme } from '@mui/material';
import {
  DragEventHandler,
  forwardRef,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import type { Table as TableType } from '@tanstack/react-table';

export const MIN_TABLE_WIDTH = 650;

interface ESTableProps {
  children: React.ReactNode;
  contentStyle?: SxProps<Theme>;
  id?: string;
  minHeight?: string | number;
  maxHeight?: string | number;
  minWidth?: string | number;
  scrollRestorationId?: string;
  initialScrollTop?: number;
  onDragOver?: DragEventHandler<HTMLDivElement>;
  onDragLeave?: DragEventHandler<HTMLDivElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instance?: TableType<any>;
}

export const ESTable = forwardRef<HTMLDivElement, ESTableProps>(
  (
    {
      children,
      contentStyle,
      id = 'tableContainerRef',
      minHeight,
      maxHeight,
      minWidth = MIN_TABLE_WIDTH,
      scrollRestorationId,
      onDragLeave,
      onDragOver,
      initialScrollTop,
      instance,
    },
    ref
  ) => {
    const tableContainerRef = useRef<HTMLDivElement | null>();

    useLayoutEffect(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTo({ top: initialScrollTop });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Example provided in @tanstack/react-table documentation for making resizable table more performant
    // See: https://tanstack.com/table/latest/docs/framework/react/examples/column-resizing-performant
    const columnSizeVars = useMemo(() => {
      const headers = instance?.getFlatHeaders();

      if (!headers) {
        return {};
      }
      const colSizes: { [key: string]: number } = {};
      for (let i = 0; i < headers.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const header = headers[i]!;
        colSizes[`--header-${header.id}-size`] = header.getSize();
        colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
      }
      return colSizes;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      instance?.getState().columnSizingInfo,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      instance?.getState().columnSizing,
    ]);

    return (
      <TableContainer
        ref={(element) => {
          if (typeof ref === 'function') {
            ref(element);
          } else if (ref) {
            ref.current = element;
          }
          tableContainerRef.current = element;
        }}
        sx={{
          flex: 1,
          minHeight,
          maxHeight,
          //TODO: Check behavior on Mac -> Firefox and Safari still have the 'jumping sticky header' issue
          backfaceVisibility: 'hidden',
        }}
        id={id}
        data-scroll-restoration-id={scrollRestorationId}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        <Table
          stickyHeader
          sx={{ minWidth, ...contentStyle }}
          style={{ ...columnSizeVars }}
        >
          {children}
        </Table>
      </TableContainer>
    );
  }
);

export const ESTableWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'relative',
      }}
    >
      {children}
    </Paper>
  );
};
