import { ELLIPSIS_TEXT_SX } from './ellipsisTextSx';
import { Cell } from '@tanstack/react-table';

export const generateTableCellStyles = <T>(
  cell: Cell<T, unknown>,
  isActionsColumn?: boolean
) => {
  const cellSize = `calc(var(--col-${cell.column.id}-size) * 1px)`;

  return {
    '&': {
      width: !isActionsColumn ? cellSize : '20px',
      minWidth:
        cell.column.getCanResize() && !isActionsColumn ? cellSize : undefined,
      maxWidth:
        cell.column.getCanResize() && !isActionsColumn ? cellSize : undefined,
      overflow: 'hidden',
    },
    whiteSpace: 'nowrap',
    backgroundColor: cell.row.getIsSelected()
      ? 'primary.light'
      : cell.column.columnDef.meta?.backgroundColor,
    padding: cell.column.columnDef.meta?.cellPadding,
    paddingRight: isActionsColumn ? 8 : undefined,
    ...(cell.column.getCanResize() ? ELLIPSIS_TEXT_SX : {}),
  };
};
